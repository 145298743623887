import React from 'react';
import SEO from '../components/core/meta/SEO';
import { Container } from '../components/core/layout/Layout';
import Heading from '../components/core/typography/Heading';
import 'twin.macro';

const MarketingPolicy = () => {
  return (
    <div tw="py-16">
      <SEO title="PRIVACY POLICY" url="/trattamento-dati-fini-commerciali/" />
      <Container tw="mb-10">
        <Heading variant="h1" tw="mb-5 md:mb-0">
          PRIVACY POLICY
        </Heading>
        <Heading variant="h4" tw="mb-10">
          s relativa all'invio della Newsletter di UGO
        </Heading>
      </Container>
      <section tw="mb-16">
        <Container>
          <div tw="space-y-12">
            <div>
              <p tw="text-paragraph mb-4">
                La newsletter di Ugo pnp S.r.l. (di seguito, "Ugo") è pubblicata
                sul sito internet e distribuita via e-mail – in automatico e
                gratuitamente – a quanti fanno richiesta di riceverla compilando
                il form "vuoi rimanere aggiornato sugli sviluppi di UGO?"
                presente all'interno della pagina partner del sito
                www.hellougo.com.
              </p>
              <p tw="text-paragraph">
                Ugo con sede legale in via Castaldi, 29, 20124 Milano (MI),
                agisce in qualità di titolare del trattamento dei dati personali
                del cliente/utente e degli eventuali coobbligati (congiuntamente
                definiti l'"Interessato") e può essere contattato all'indirizzo
                e-mail info@hellougo.com.
              </p>
              <p tw="text-paragraph">
                I dati richiesti e raccolti mediante il modulo di adesione alla
                newsletter (nome, cognome e contatto e-mail) saranno trattati
                con finalità di promozione di prodotti e servizi offerti da Ugo
                e l'esecuzione di ricerche di mercato volte a rendere le
                attività promozionali più focalizzate sulle esigenze, abitudini
                e gli interessi degli Interessati. I dati saranno trattati al
                solo fine di fornire il servizio richiesto e saranno conservati
                esclusivamente per il periodo in cui lo stesso sarà attivo e
                successivamente per un periodo fino a 24 mesi successivi al
                termine del servizio per attività di marketing.
              </p>
              <p tw="text-paragraph">
                La base giuridica di tale trattamento è da rinvenirsi nel
                consenso fornito dall'Interessato mediante la compilazione del
                form e la richiesta di attivazione del servizio di newsletter,
                ai sensi e per gli effetti dell'art. 6, §1, lett. a) del Reg.to
                UE/679/2016. Il consenso prestato dall'Interessato è revocabile
                in ogni momento presentando una richiesta scritta all'indirizzo
                e-mail dedicato (info@hellougo.com): qualora l'Interessato neghi
                il suo consenso al servizio, questi non potrà più ricevere
                comunicazioni commerciali, partecipare a ricerche di mercato,
                ricevere comunicazioni e servizi correlati.
              </p>
              <p tw="text-paragraph">
                Le modalità del trattamento dei dati raccolti sarà realizzata
                per mezzo delle operazioni indicate all'art. 4 n. 2) GDPR e
                precisamente: raccolta, registrazione, organizzazione,
                conservazione, consultazione, elaborazione, modificazione,
                estrazione, utilizzo, comunicazione mediante ogni forma a
                disposizione, cancellazione e distruzione dei dati. I dati
                possono essere trattati con strumenti manuali o informatici,
                idonei a garantirne la sicurezza, la riservatezza e ad evitare
                accessi non autorizzati. I dati saranno trattati esclusivamente
                dal personale e dai collaboratori di Ugo e/o dalle imprese
                espressamente nominate come responsabili del trattamento (ad es.
                per esigenze di manutenzione tecnologica del sito – l'elenco
                completo di tali soggetti può essere visionato mediante espressa
                richiesta da inviare all'indirizzo info@hellougo.com). I dati
                potranno essere liberamente trasferiti fuori dal territorio
                nazionale a Paesi situati nell'Unione europea. L'Interessato
                avrà il diritto di ottenere una copia dei dati detenuti
                all'estero e di ottenere informazioni circa il luogo dove tali
                dati sono conservati facendone espressa richiesta a Ugo
                all'indirizzo dedicato (info@hellougo.com).
              </p>
              <p tw="text-paragraph">
                Gli interessati hanno il diritto di ottenere da Ugo, nei casi
                previsti, l'accesso ai dati personali e la rettifica o la
                cancellazione degli stessi o la limitazione del trattamento che
                li riguarda o di opporsi al trattamento (art. 15 e ss. Reg.to
                UE/679/2016). L'apposita istanza dovrà essere presentata
                contattando Ugo (via Castaldi n. 29 – 20124 – Milano; email:
                info@hellougo.com). Gli interessati che ritengono che il
                trattamento dei dati personali a loro riferiti effettuato
                attraverso questo servizio avvenga in violazione di quanto
                previsto dal Reg.to UE/679/2016 hanno il diritto di proporre
                reclamo al Garante, come previsto dall'art. 77 del Regolamento
                stesso, o di adire le opportune sedi giudiziarie (art. 79 Reg.to
                UE/679/2016).
              </p>
              <p tw="text-paragraph">
                Per non ricevere più la newsletter, l'Interessato può richiedere
                la cancellazione del servizio inviando richiesta scritta
                all'indirizzo e-mail info@hellougo.com.
              </p>
              <p tw="text-paragraph">
                Ultimo aggiornamento del presente documento: 20 novembre 2019
              </p>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default MarketingPolicy;
